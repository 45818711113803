/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "idv/backend/api/v1/idv_types.proto" (package "idv.backend.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message idv.backend.api.v1.VerificationInfo
 */
export interface VerificationInfo {
    /**
     * Provides information about the verification state in the attempt.
     *
     * @generated from protobuf field: idv.backend.api.v1.AttemptState attempt_state = 1;
     */
    attemptState: AttemptState;
    /**
     * Provides information about the IDV method/vendor used in the attempt.
     *
     * @generated from protobuf field: idv.backend.api.v1.IdvMethod idv_method = 2;
     */
    idvMethod: IdvMethod;
    /**
     * Timestamp of the attempt.
     *
     * @generated from protobuf field: string timestamp = 3;
     */
    timestamp: string;
    /**
     * Given name of the patient received from the vendor or the manual verifier.
     *
     * @generated from protobuf field: string given_name = 4;
     */
    givenName: string;
    /**
     * Family name of the patient received from the vendor or the manual verifier.
     *
     * @generated from protobuf field: string family_name = 5;
     */
    familyName: string;
    /**
     * DOB of the patient received from the vendor or the manual verifier.
     *
     * @generated from protobuf field: string date_of_birth = 6;
     */
    dateOfBirth: string;
    /**
     * Type of document received from the vendor or the manual verifier.
     *
     * @generated from protobuf field: string document_type = 7;
     */
    documentType: string;
    /**
     * Additional metadata about the attempt that needs to be stored.
     * Like document number, notes about verification, etc.
     *
     * @generated from protobuf field: repeated string additional_metadata = 8;
     */
    additionalMetadata: string[];
}
/**
 * Provides information about the overall verification status of the Participant.
 * Maps to https://github.com/google/fhir/blob/358f39a5fae0faa006c9a630ca113f03d191e929/go/proto/google/fhir/proto/r4/core/codes_go_proto/codes.pb.go#L13544
 *
 * @generated from protobuf enum idv.backend.api.v1.VerificationState
 */
export enum VerificationState {
    /**
     * Default value. Used for unknown/attested states.
     *
     * @generated from protobuf enum value: VERIFICATION_STATE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * Participant or Admin started verification process, but not yet complete.
     *
     * @generated from protobuf enum value: VERIFICATION_STATE_IN_PROGRESS = 1;
     */
    IN_PROGRESS = 1,
    /**
     * Participant's identity was successfully verified.
     *
     * @generated from protobuf enum value: VERIFICATION_STATE_SUCCESS = 2;
     */
    SUCCESS = 2,
    /**
     * Identity Verification failed by vendor decision.
     *
     * @generated from protobuf enum value: VERIFICATION_STATE_FAILURE = 3;
     */
    FAILURE = 3,
    /**
     * Verification resubmission by requested by vendor.
     *
     * @generated from protobuf enum value: VERIFICATION_STATE_RESUBMISSION = 4;
     */
    RESUBMISSION = 4,
    /**
     * Verification not completed by participant and declared abandoned.
     *
     * @generated from protobuf enum value: VERIFICATION_STATE_ABANDONED = 5;
     */
    ABANDONED = 5,
    /**
     * Verification failed in re-evaluation stage (manual verification).
     *
     * @generated from protobuf enum value: VERIFICATION_STATE_REVALIDATION_FAILURE = 6;
     */
    REVALIDATION_FAILURE = 6
}
/**
 * Provides information about the verification state of the IDV attempt.
 * Maps to https://github.com/verily-src/verily-fhir-ig/blob/d95b29034b9445b08cc94dbb3ee037dec7efee83/input/fsh/codeSystems/VerilyIdVerificationAttemptStatus.fsh
 *
 * @generated from protobuf enum idv.backend.api.v1.AttemptState
 */
export enum AttemptState {
    /**
     * Default value.
     *
     * @generated from protobuf enum value: ATTEMPT_STATE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * Participant or Admin started verification process, but not yet complete.
     *
     * @generated from protobuf enum value: ATTEMPT_STATE_IN_PROGRESS = 1;
     */
    IN_PROGRESS = 1,
    /**
     * Participant's identity was successfully verified.
     *
     * @generated from protobuf enum value: ATTEMPT_STATE_SUCCESS = 2;
     */
    SUCCESS = 2,
    /**
     * Identity Verification failed by vendor decision.
     *
     * @generated from protobuf enum value: ATTEMPT_STATE_FAILURE = 3;
     */
    FAILURE = 3,
    /**
     * Verification resubmission by requested by vendor.
     *
     * @generated from protobuf enum value: ATTEMPT_STATE_RESUBMISSION = 4;
     */
    RESUBMISSION = 4,
    /**
     * Verification not completed by participant and declared abandoned.
     *
     * @generated from protobuf enum value: ATTEMPT_STATE_ABANDONED = 5;
     */
    ABANDONED = 5
}
/**
 * Provides information about the IDV method/vendor used in the attempt.
 *
 * @generated from protobuf enum idv.backend.api.v1.IdvMethod
 */
export enum IdvMethod {
    /**
     * The method/vendor is unknown or unspecified. Common processor is used.
     *
     * @generated from protobuf enum value: IDV_METHOD_DEFAULT = 0;
     */
    DEFAULT = 0,
    /**
     * Veriff (3P IDV Vendor) was used.
     *
     * @generated from protobuf enum value: IDV_METHOD_VERIFF = 1;
     */
    VERIFF = 1,
    /**
     * The result is from manual flow.
     *
     * @generated from protobuf enum value: IDV_METHOD_MANUAL_FLOW = 2;
     */
    MANUAL_FLOW = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class VerificationInfo$Type extends MessageType<VerificationInfo> {
    constructor() {
        super("idv.backend.api.v1.VerificationInfo", [
            { no: 1, name: "attempt_state", kind: "enum", T: () => ["idv.backend.api.v1.AttemptState", AttemptState, "ATTEMPT_STATE_"] },
            { no: 2, name: "idv_method", kind: "enum", T: () => ["idv.backend.api.v1.IdvMethod", IdvMethod, "IDV_METHOD_"] },
            { no: 3, name: "timestamp", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "given_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "family_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "date_of_birth", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "document_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "additional_metadata", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerificationInfo>): VerificationInfo {
        const message = { attemptState: 0, idvMethod: 0, timestamp: "", givenName: "", familyName: "", dateOfBirth: "", documentType: "", additionalMetadata: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<VerificationInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerificationInfo): VerificationInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* idv.backend.api.v1.AttemptState attempt_state */ 1:
                    message.attemptState = reader.int32();
                    break;
                case /* idv.backend.api.v1.IdvMethod idv_method */ 2:
                    message.idvMethod = reader.int32();
                    break;
                case /* string timestamp */ 3:
                    message.timestamp = reader.string();
                    break;
                case /* string given_name */ 4:
                    message.givenName = reader.string();
                    break;
                case /* string family_name */ 5:
                    message.familyName = reader.string();
                    break;
                case /* string date_of_birth */ 6:
                    message.dateOfBirth = reader.string();
                    break;
                case /* string document_type */ 7:
                    message.documentType = reader.string();
                    break;
                case /* repeated string additional_metadata */ 8:
                    message.additionalMetadata.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerificationInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* idv.backend.api.v1.AttemptState attempt_state = 1; */
        if (message.attemptState !== 0)
            writer.tag(1, WireType.Varint).int32(message.attemptState);
        /* idv.backend.api.v1.IdvMethod idv_method = 2; */
        if (message.idvMethod !== 0)
            writer.tag(2, WireType.Varint).int32(message.idvMethod);
        /* string timestamp = 3; */
        if (message.timestamp !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.timestamp);
        /* string given_name = 4; */
        if (message.givenName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.givenName);
        /* string family_name = 5; */
        if (message.familyName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.familyName);
        /* string date_of_birth = 6; */
        if (message.dateOfBirth !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.dateOfBirth);
        /* string document_type = 7; */
        if (message.documentType !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.documentType);
        /* repeated string additional_metadata = 8; */
        for (let i = 0; i < message.additionalMetadata.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.additionalMetadata[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message idv.backend.api.v1.VerificationInfo
 */
export const VerificationInfo = new VerificationInfo$Type();
