import {
  CssBaseline,
  LinkProps as MuiLinkProps,
  ThemeProvider,
} from '@mui/material';
import {BrowserRouter, Link, LinkProps, Route, Routes} from 'react-router-dom';
// eslint-disable-next-line node/no-extraneous-import
import {deepmerge} from '@mui/utils';
import {EnterpriseLightTheme} from '@verily-src/react-design-system';
import {forwardRef} from 'react';
import IdentityVerification from '../../pages/IdentityVerification/IdentityVerification';
// eslint-disable-next-line node/no-extraneous-import
import {ErrorBoundary} from 'react-error-boundary';
import {Error} from '../Error/Error';

export function App() {
  return (
    <ErrorBoundary
      FallbackComponent={() => (
        <Error message={"Sorry, we're having some issues."} />
      )}
    >
      <Routes>
        <Route path="/me/bundle/idv" element={<IdentityVerification />}></Route>
      </Routes>
    </ErrorBoundary>
  );
}

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, 'to'> & {href: LinkProps['to']}
>((props, ref) => {
  const {href, ...other} = props;
  // Map href (MUI) -> to (react-router)
  return <Link data-testid="custom-link" ref={ref} to={href} {...other} />;
});
const reactRouterLinksTheme = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as MuiLinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
};

export default function Root() {
  return (
    <BrowserRouter>
      <ThemeProvider
        theme={deepmerge(EnterpriseLightTheme, reactRouterLinksTheme)}
      >
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  );
}
