/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "idv/participant-bff/api/v1/idv_participant_bff_service.proto" (package "idv.participant_bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { IdvParticipantBffService } from "./idv_participant_bff_service";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { StartIdvForParticipantResponse } from "./idv_participant_bff_service";
import type { StartIdvForParticipantRequest } from "./idv_participant_bff_service";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * IDV Service BFF for Participant Facing MFE.
 *
 * @generated from protobuf service idv.participant_bff.api.v1.IdvParticipantBffService
 */
export interface IIdvParticipantBffServiceClient {
    /**
     * Starts an ID Verification flow.
     *
     * @generated from protobuf rpc: StartIdvForParticipant(idv.participant_bff.api.v1.StartIdvForParticipantRequest) returns (idv.participant_bff.api.v1.StartIdvForParticipantResponse);
     */
    startIdvForParticipant(input: StartIdvForParticipantRequest, options?: RpcOptions): UnaryCall<StartIdvForParticipantRequest, StartIdvForParticipantResponse>;
}
/**
 * IDV Service BFF for Participant Facing MFE.
 *
 * @generated from protobuf service idv.participant_bff.api.v1.IdvParticipantBffService
 */
export class IdvParticipantBffServiceClient implements IIdvParticipantBffServiceClient, ServiceInfo {
    typeName = IdvParticipantBffService.typeName;
    methods = IdvParticipantBffService.methods;
    options = IdvParticipantBffService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * Starts an ID Verification flow.
     *
     * @generated from protobuf rpc: StartIdvForParticipant(idv.participant_bff.api.v1.StartIdvForParticipantRequest) returns (idv.participant_bff.api.v1.StartIdvForParticipantResponse);
     */
    startIdvForParticipant(input: StartIdvForParticipantRequest, options?: RpcOptions): UnaryCall<StartIdvForParticipantRequest, StartIdvForParticipantResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<StartIdvForParticipantRequest, StartIdvForParticipantResponse>("unary", this._transport, method, opt, input);
    }
}
