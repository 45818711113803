import {base64encode} from '@protobuf-ts/runtime';
import '@verily-src/phaf-runtime-helpers';
import {BatchCheckAuthorizationResponse} from '@verily-src/verily1-protos/ciam/backend/api/v1/ciam';
import {sign} from 'jsonwebtoken';

export default function generateFakeAccessToken(
  subjectId: string,
  permissions: string[]
) {
  const encodedResponse = base64encode(
    BatchCheckAuthorizationResponse.toBinary({
      permissions: permissions,
      targetPermissions: {},
    })
  );

  const accessToken = sign(
    {
      emailAddr: 'mestopinal@verily.com',
      personId: subjectId,
      sub: subjectId,
      expected_checkauthorization_error_code: 0,
      expected_batchcheckauthorization_response: encodedResponse,
      expected_batchcheckauthorization_error_code: 0,
      Subject: 'subject',
      Issuer: 'verily-us-autopush-pro.us.auth0.com',
      Audience: ['participant.ciam-localdev.api.verily.com'],
    },
    window.crypto.randomUUID()
  );
  return accessToken;
}
