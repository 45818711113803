import {Box, Typography} from '@mui/material';

interface ErrorProps {
  message: string;
}

export const Error: React.FC<ErrorProps> = ({message}) => {
  return (
    <Box
      sx={{
        gap: '12px',
        height: '350px',
        paddingLeft: '24px',
        paddingTop: '24px',
        width: '537px',
      }}
    >
      <Typography align="left" variant={'h6'}>
        {message}
      </Typography>
    </Box>
  );
};
