import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';

// This method either calls the mock API or the real one, depending on environment variables
// Having different return types for rpcCallback and mockCallback prevents accidentally switching them
export default async function maybeMockRpc<Request, Response>(
  request: Request,
  rpcCallback: (request: Request) => Promise<Response>,
  mockCallback: (request: Request) => Response
): Promise<Response> {
  if (config.getBoolean('REACT_MOCK_API')) {
    return mockCallback(request);
  } else {
    return rpcCallback(request);
  }
}
