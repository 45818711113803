// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redBorder__ykwQy{border:1px solid red}.marginFour__DY5ac{margin:.25rem}.spinnerOverlay__R_ndV{align-items:center;background-color:rgba(0,0,0,.25);border-radius:1rem;bottom:0;display:flex;justify-content:center;left:0;position:absolute;right:0;top:0}.startIdvButton__uVx8q{align-self:right}`, "",{"version":3,"sources":["webpack://./src/pages/IdentityVerification/IdentityVerification.module.css"],"names":[],"mappings":"AAAA,kBACE,oBACF,CAEA,mBACE,aACF,CAEA,uBAWE,kBAAmB,CALnB,gCAAqC,CACrC,kBAAmB,CAJnB,QAAS,CAOT,YAAa,CAEb,sBAAuB,CARvB,MAAO,CAHP,iBAAkB,CAIlB,OAAQ,CAHR,KAWF,CAEA,uBACE,gBACF","sourcesContent":[".redBorder {\n  border: 1px solid red;\n}\n\n.marginFour {\n  margin: 0.25rem;\n}\n\n.spinnerOverlay {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.25); /* half as opaque as dialog backdrop */\n  border-radius: 1rem; /* matches parent */\n\n  /* center the spinner in the overlay */\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.startIdvButton {\n  align-self: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redBorder": `redBorder__ykwQy`,
	"marginFour": `marginFour__DY5ac`,
	"spinnerOverlay": `spinnerOverlay__R_ndV`,
	"startIdvButton": `startIdvButton__uVx8q`
};
export default ___CSS_LOADER_EXPORT___;
