import {useState} from 'react';

/**
 * useDialog is a custom React hook that encapsulates the state and logic
 * related to opening and closing a dialog component. This makes it easy to
 * reuse dialog functionality across different parts of an application without
 * duplicating code.
 *
 * Example Usage:
 * const dialog = useDialog();
 *  <button onClick={dialog.openDialog}>Open Dialog</button>
 *  <MyDialog open={dialog.isOpen} onClose={dialog.closeDialog} />
 */
export const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  return {isOpen, openDialog, closeDialog};
};
