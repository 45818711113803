/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "ciam/backend/api/v1/ciam.proto" (package "ciam.backend.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { Identifier } from "../../../../proto/google/fhir/proto/r4/core/datatypes";
/**
 * @generated from protobuf message ciam.backend.api.v1.DecisionDetails
 */
export interface DecisionDetails {
    /**
     * @generated from protobuf field: ciam.backend.api.v1.AuthorizationDecision decision = 1;
     */
    decision: AuthorizationDecision;
    /**
     * Mostly error message if decision code is not OK
     *
     * @generated from protobuf field: string message = 2;
     */
    message: string;
    /**
     * WIP: the intent is to summarize what we believe the user is allowed to do
     * in some format that is easy for e.g. Cortex to use.
     *
     * @generated from protobuf field: string actor_id = 3;
     */
    actorId: string;
    /**
     * @generated from protobuf field: string target_id = 4;
     */
    targetId: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.UserInfo
 */
export interface UserInfo {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: string person_id = 2;
     */
    personId: string;
    /**
     * @generated from protobuf field: string ciam_id = 3;
     */
    ciamId: string;
    /**
     * @generated from protobuf field: ciam.backend.api.v1.TokenType token_type = 4;
     */
    tokenType: TokenType;
    /**
     * In the format "+<country_code><phone_number>" without spaces
     *
     * @generated from protobuf field: string phone_number = 5;
     */
    phoneNumber: string;
    /**
     * Note: a PSAT user will not have a connection.
     * Connection name the user logged-in through, examples:
     *  - Enterprise:
     *    - verily-sso                  --> Veep SSO
     *    - verily-pro                  --> Dev Database
     *    - verily-professional         --> Non-Dev Database
     *    - google-oauth2               --> Google Social
     *    - workbench-wellspan-azure-ad --> Prod only
     *
     *  - Verily Me
     *    - verily-sso         --> Veep SSO
     *    - verily-participant --> Database
     *    - email              --> Email Passwordless (OTP)
     *    - sms                --> SMS Passwordless (OTP)
     *
     * @generated from protobuf field: string connection = 6;
     */
    connection: string;
    /**
     * List of Patient IDs to which the FHIR Person is linked.
     * Deprecated: This list can be outdated and may not contain newly created/linked Patients.
     *
     * @generated from protobuf field: repeated string patient_ids = 7;
     */
    patientIds: string[];
    /**
     * List of Practitioner IDs to which the FHIR person is linked.
     * Deprecated: This list can be outdated and may not contain newly created/linked Practitioners.
     *
     * @generated from protobuf field: repeated string practitioner_ids = 8;
     */
    practitionerIds: string[];
}
/**
 * AuthorizationInfo contains a subset of the data involved in authorization check against which
 * CIAM has vetted the logged-in user.
 *
 * @generated from protobuf message ciam.backend.api.v1.AuthorizationInfo
 */
export interface AuthorizationInfo {
    /**
     * Target FHIR Organization id, without prefixes, that the authorization check succeeded against.
     * Will not be empty in enterprise system RPCs with annotated permissions. Will be empty in RPCs
     * not annotated with is_target, i.e. most of the Participant-facing RPCs such as in Verily Me.
     * Example: "cd3328e5-4fe5-492e-b503-793c3368ac25"
     *
     * @generated from protobuf field: string target_organization = 1;
     */
    targetOrganization: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.Auth0Config
 */
export interface Auth0Config {
    /**
     * @generated from protobuf field: string domain = 1;
     */
    domain: string;
    /**
     * @generated from protobuf field: string client_id = 2;
     */
    clientId: string;
    /**
     * @generated from protobuf field: string audience = 3;
     */
    audience: string;
}
/**
 * Basic authorization check whether the current user (i.e. the owner of the access token sent in the
 * request metadata) is permitted to perform an operation. For these checks, the current user must
 * be authorized to perform operations as the actor, e.g. the actor is an Account owned by the
 * current user, or the current user is the actor's guardian.
 * NEXT ID = 10
 *
 * @generated from protobuf message ciam.backend.api.v1.CheckAuthorizationRequest
 */
export interface CheckAuthorizationRequest {
    /**
     * Non-empty list of permissions needed for authorization check.
     * If only authentication check is required, use ALLOW_AUTHENTICATED.
     *
     * @generated from protobuf field: repeated string permissions = 1;
     */
    permissions: string[];
    /**
     * The generally used keys are:
     * - actor: the ID of the actor to check. For Enterprise, this is often the Account ID.
     * - target: The target resource of the authorization check. (Not all checks have targets).
     * - operation: The RPC name or any other particular operation, needed for reauth.
     * Any other custom keys needed for authorization check can be added.
     *
     * @generated from protobuf field: map<string, string> inputs = 2;
     */
    inputs: {
        [key: string]: string;
    };
    /**
     * If marked true, CIAM reads IdToken header from metadata and validates it. The validation results
     * are sent back in the response.
     *
     * @generated from protobuf field: bool validate_id_token = 3;
     */
    validateIdToken: boolean;
    /**
     * If true, do not match PersonId from access token claims with 'actor' in inputs.
     * Warning: This flag skips a crucial check that may result in unauthorized access of sensitive data.
     * Set this flag to true ONLY when the permissions do not involve any user or organization-related data,
     * e.g. getting feature flags, etc.
     *
     * @generated from protobuf field: bool skip_actor_person_match = 4;
     */
    skipActorPersonMatch: boolean;
    /**
     * Reauthentication-related information. Setting this field signifies reauthentication is needed.
     *
     * @generated from protobuf field: ciam.backend.api.v1.Reauthentication reauth = 5;
     */
    reauth?: Reauthentication;
    /**
     * If true, allow the current access token to be a pre-signed up access token.
     * PSAT will be validated differently and if this flag is false, getting a PSAT will result in PermissionDenied error.
     *
     * @generated from protobuf field: bool accept_psat = 6;
     */
    acceptPsat: boolean;
    /**
     * Mark this as true if the caller expects values set as metadata (ciam-info, ciam-aqp, etc.) and
     * common decision codes (401/403) as a response field instead.
     * TODO(PHP-21348): Remove this field
     *
     * @generated from protobuf field: bool return_metadata_in_response_message = 7;
     */
    returnMetadataInResponseMessage: boolean;
    /**
     * If set, CIAM looks up Auth0 config values for this app.
     *
     * @generated from protobuf field: string app_name = 8;
     */
    appName: string;
    /**
     * If set, CIAM will validate the token and return user info without performing an access check.
     *
     * @generated from protobuf field: bool user_info_only = 9;
     */
    userInfoOnly: boolean;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.Reauthentication
 */
export interface Reauthentication {
    /**
     * The operation for this requested reauthentication. CIAM checks if this value matches ext-fro in
     * access token custom claims. If it doesn't, CIAM returns headers back to caller to initiate reauth
     * with this value as ext-fro. This value is set by the CIAM BFF client interceptor as the RPC FullMethod
     * (https://pkg.go.dev/google.golang.org/grpc#UnaryServerInfo).
     *
     * @generated from protobuf field: string operation = 1;
     */
    operation: string;
    /**
     * The object id for this requested reauthentication. CIAM checks if this value matches ext-frid in
     * access token custom claims. If it doesn't, CIAM returns headers back to caller to initiate reauth
     * with this value as ext-frid. This value is set by the field annotation "is_reauth_object".
     *
     * @generated from protobuf field: string object_id = 2;
     */
    objectId: string;
}
/**
 * NEXT ID = 8
 *
 * @generated from protobuf message ciam.backend.api.v1.CheckAuthorizationResponse
 */
export interface CheckAuthorizationResponse {
    /**
     * is_valid_id_token is set to true ONLY when the following conditions are met:
     * 1. the validate_id_token flag in req is set to true
     * 2. the incoming context contains the key IdToken
     * 3. the IdToken value is valid
     * Otherwise, the value is false.
     *
     * @generated from protobuf field: bool is_valid_id_token = 1;
     */
    isValidIdToken: boolean;
    /**
     * id_token_invalid_reason contains the description of non-validity of the IdToken and
     * is set ONLY when the following conditions are met:
     * 1. the validate_id_token flag in req is set to true
     * 2. the incoming context does not contain the key IdToken OR the IdToken value is not valid
     * Otherwise, the value is empty string.
     *
     * @generated from protobuf field: string id_token_invalid_reason = 2;
     */
    idTokenInvalidReason: string;
    /**
     * When the request specifies app_name, this contains the values to
     * use when interacting with Auth0 based on the application.
     *
     * @generated from protobuf field: ciam.backend.api.v1.Auth0Config auth0_config = 3;
     */
    auth0Config?: Auth0Config;
    /**
     * user_info is array of UserInfo for all the tokens that are presented to CIAM BE
     * Currently The list will contain two items only when both auth0 token and psat are passed to CIAM BE
     * Only the first item in list which is also the token we use for access check
     * will be provided to BFF handlers via CIAMInfo map when
     * decision = OK.
     *
     * @generated from protobuf field: repeated ciam.backend.api.v1.UserInfo user_info = 4;
     */
    userInfo: UserInfo[];
    /**
     * For unauthenticated responses (decision = UNAUTHENTICATED) that require special
     * handling, this contains values the client should set as query params to Auth0.
     *
     * @generated from protobuf field: map<string, string> additional_query_params = 5;
     */
    additionalQueryParams: {
        [key: string]: string;
    };
    /**
     * Decision and more information about the decision which can be used to validate that any
     * actions taken by the BFF are within what CIAM checked.
     *
     * @generated from protobuf field: ciam.backend.api.v1.DecisionDetails decision_details = 6;
     */
    decisionDetails?: DecisionDetails;
    /**
     * authorization_info provides information involved in authorization check against which CIAM has
     * vetted the logged-in user. This message does not contain all the information involved in the
     * check. Further information will be added when needed.
     * This field will be set whenever decision is OK. The contents may be empty, depending on the
     * permission checks performed, e.g. enterprise, verily me, reauth, authentication only, etc.
     *
     * @generated from protobuf field: ciam.backend.api.v1.AuthorizationInfo authorization_info = 7;
     */
    authorizationInfo?: AuthorizationInfo;
}
/**
 * Similar to CheckAuthorizationRequest. However the response message will return a list of authorized
 * permissions among the permissions provided in request
 *
 * @generated from protobuf message ciam.backend.api.v1.BatchCheckAuthorizationRequest
 */
export interface BatchCheckAuthorizationRequest {
    /**
     * Non-empty list of permissions or nav-paths needed for authorization check.
     * Should not contain both permissions and nav-paths in the same request.
     *
     * @generated from protobuf field: repeated string permissions = 1;
     */
    permissions: string[];
    /**
     * *
     * The ID of the actor to check permissions against. For Enterprise, this is often the Account ID.
     * The ID can be preceded by a resource type, e.g. "professionalAccounts/<fhir_resource_id>".
     * Any further prefixes are ignored by CIAM. The prefix can be anything that CIAM understands.
     * CIAM considers an actor ID without any prefix, or an unrecognized prefix, to be Practitioner ID
     * by default. The prefixes are case-sensitive.
     *
     * FHIR resource types supported as actor:
     * - Practitioner (default, supported prefixes: professionalAccounts)
     * - Patient (supported prefixes: participantRecords, Patient)
     *
     * Examples of valid actor values:
     * - "d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "professionalAccounts/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "blah/professionalAccounts/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "heres-an-id": assuming a Practitioner in FHIR store exists with ID "heres-an-id"
     * - "blah/blah/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID by default as the
     *    prefix is unrecognized
     * - "Patient/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": actor can be a Patient
     *
     * Examples of invalid actor values:
     * - "patient/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": prefix is case-sensitive and, thus,
     *    unrecognized and the uuid is treated as a Practitioner ID
     * - "participant/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": prefix is unrecognized and CIAM treats
     *    the uuid as a Practitioner ID by default
     * - "organizations/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": CIAM does not support Organization ID as
     *    actor
     *
     * @generated from protobuf field: string actor = 2;
     */
    actor: string;
    /**
     * Deprecated: use targets instead. If this field is set, it overrides targets completely for
     * backward compatibility.
     * TODO(PHP-42240): remove this field.
     *
     * @generated from protobuf field: string target = 3;
     */
    target: string;
    /**
     * *
     * Heterogenous list of target IDs to check permissions against. These are often Organization IDs.
     * The ID can be preceded by a resource type, e.g. "organizations/<fhir_resource_id>".
     * Any further prefixes are ignored by CIAM. The prefix can be anything that CIAM understands.
     * CIAM considers a target ID without any prefix, or an unrecognized prefix, to be Organization ID
     * by default. The prefixes are case-sensitive.
     *
     * FHIR resource types supported as target:
     * - Organization (default, supported prefixes: organizations)
     * - Patient (supported prefixes: participantRecords, Patient)
     *   If target is of type Patient, the patient's managing organization ID is considered.
     *
     * Examples of valid target values:
     * - "250558ba-5c19-432c-8954-36d1a6e1efd7": Organization ID
     * - "organizations/250558ba-5c19-432c-8954-36d1a6e1efd7": Organization ID
     * - "blah/organizations/250558ba-5c19-432c-8954-36d1a6e1efd7": Organization ID
     * - "heres-an-id": assuming an Organization in FHIR store exists with ID "heres-an-id"
     * - "blah/blah/250558ba-5c19-432c-8954-36d1a6e1efd7": Organization ID by default
     * - "Patient/250558ba-5c19-432c-8954-36d1a6e1efd7": target can be a Patient
     * - "participantRecords/250558ba-5c19-432c-8954-36d1a6e1efd7": prefix is recognized as a Patient
     *
     * Examples of invalid target values:
     * - "patient/250558ba-5c19-432c-8954-36d1a6e1efd7": prefix is case-sensitive and thus unrecognized
     *    and the uuid is treated as an Organization ID
     * - "participant/250558ba-5c19-432c-8954-36d1a6e1efd7": prefix is unrecognized and CIAM treats the
     *    uuid as an Organization ID by default
     * - "professionalAccounts/250558ba-5c19-432c-8954-36d1a6e1efd7": as CIAM does not support
     *    Practitioner ID as target
     *
     * @generated from protobuf field: repeated string targets = 5;
     */
    targets: string[];
    /**
     * Deprecated: setting this flag will not do anything. It's left here to support current callers.
     *
     * @generated from protobuf field: bool use_rbac = 4;
     */
    useRbac: boolean;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.BatchCheckAuthorizationResponse
 */
export interface BatchCheckAuthorizationResponse {
    /**
     * List of authorized permissions if a single target (deprecated) is specified.
     * Deprecated: use target_permissions instead.
     * TODO(PHP-42240): remove this field.
     *
     * @generated from protobuf field: repeated string permissions = 1;
     */
    permissions: string[];
    /**
     * Map of target id to authorized permissions or nav-paths. The list of permissions/nav-paths in
     * this map is a subset of permissions/nav-paths received in the request.
     * The keys are targets as received in the request, verbatim.
     *
     * @generated from protobuf field: map<string, ciam.backend.api.v1.PermissionList> target_permissions = 2;
     */
    targetPermissions: {
        [key: string]: PermissionList;
    };
}
/**
 * @generated from protobuf message ciam.backend.api.v1.PermissionList
 */
export interface PermissionList {
    /**
     * @generated from protobuf field: repeated string permissions = 1;
     */
    permissions: string[];
}
/**
 * @generated from protobuf message ciam.backend.api.v1.GetAssignedRoleFeaturesRequest
 */
export interface GetAssignedRoleFeaturesRequest {
    /**
     * *
     * The ID of the actor to get assigned role features for. For Enterprise, this is often the Account
     * ID. The ID can be preceded by a resource type, e.g. "professionalAccounts/<fhir_resource_id>".
     * Any further prefixes are ignored by CIAM. The prefix can be anything that CIAM understands.
     * CIAM considers an actor ID without any prefix, or an unrecognized prefix, to be Practitioner ID
     * by default. The prefixes are case-sensitive.
     *
     * FHIR resource types supported as actor:
     * - Practitioner (default, supported prefixes: professionalAccounts)
     * - Patient (supported prefixes: participantRecords, Patient)
     *
     * Examples of valid actor values:
     * - "d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "professionalAccounts/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "blah/professionalAccounts/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "heres-an-id": assuming a Practitioner in FHIR store exists with ID "heres-an-id"
     * - "blah/blah/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID by default as the
     *    prefix is unrecognized
     * - "Patient/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": actor can be a Patient
     *
     * Examples of invalid actor values:
     * - "patient/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": prefix is case-sensitive and, thus,
     *    unrecognized and the uuid is treated as a Practitioner ID
     * - "participant/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": prefix is unrecognized and CIAM treats
     *    the uuid as a Practitioner ID by default
     * - "organizations/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": CIAM does not support Organization ID as
     *    actor
     *
     * @generated from protobuf field: string actor = 1;
     */
    actor: string;
    /**
     * *
     * The target organization ID under which the role features assigned to the actor are returned.
     * The ID can be preceded by a resource type, e.g. "organizations/<fhir_resource_id>".
     * Any further prefixes are ignored by CIAM. The prefix can be anything that CIAM understands.
     * CIAM considers a target ID without any prefix, or an unrecognized prefix, to be Organization ID
     * by default. The prefixes are case-sensitive.
     *
     * FHIR resource types supported as target:
     * - Organization (default, supported prefixes: organizations)
     *
     * Note that this RPC does not recognize a Patient resource ID as target.
     *
     * Examples of valid target values:
     * - "250558ba-5c19-432c-8954-36d1a6e1efd7": Organization ID
     * - "organizations/250558ba-5c19-432c-8954-36d1a6e1efd7" : Organization ID
     * - "blah/organizations/250558ba-5c19-432c-8954-36d1a6e1efd7": Organization ID
     * - "heres-an-id" : assuming an Organization in FHIR store exists with ID "heres-an-id"
     * - "blah/blah/250558ba-5c19-432c-8954-36d1a6e1efd7": Organization ID by default
     *
     * Examples of invalid target values:
     * - "Patient/250558ba-5c19-432c-8954-36d1a6e1efd7": Patient as target is not supported
     * - "participant/250558ba-5c19-432c-8954-36d1a6e1efd7": prefix is unrecognized and CIAM treats the
     *    uuid as an Organization ID by default
     * - "professionalAccounts/250558ba-5c19-432c-8954-36d1a6e1efd7": as CIAM does not support
     *    Practitioner ID as target
     *
     * @generated from protobuf field: string target = 2;
     */
    target: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.GetAssignedRoleFeaturesResponse
 */
export interface GetAssignedRoleFeaturesResponse {
    /**
     * A list of role features (and role sub features) that the actor has been assigned in the target
     * organization or any of its descendants. If the actor is inactive, this list will be empty.
     * This list will have unique role features and role sub features, i.e. if multiple assigned roles
     * have the same feature/sub-feature this list will contain the feature/sub-feature only once.
     *
     * @generated from protobuf field: repeated string role_features = 1;
     */
    roleFeatures: string[];
}
/**
 * @generated from protobuf message ciam.backend.api.v1.ListAuthorizedOrganizationsRequest
 */
export interface ListAuthorizedOrganizationsRequest {
    /**
     * *
     * The ID of the actor to check permissions against. For Enterprise, this is often the Account ID.
     * The ID can be preceded by a resource type, e.g. "professionalAccounts/<fhir_resource_id>".
     * Any further prefixes are ignored by CIAM. The prefix can be anything that CIAM understands.
     * CIAM considers an actor ID without any prefix, or an unrecognized prefix, to be Practitioner ID
     * by default. The prefixes are case-sensitive.
     *
     * FHIR resource types supported as actor:
     * - Practitioner (default, supported prefixes: professionalAccounts)
     * - Patient (supported prefixes: participantRecords, Patient)
     *
     * Examples of valid actor values:
     * - "d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "professionalAccounts/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "blah/professionalAccounts/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID
     * - "heres-an-id": assuming a Practitioner in FHIR store exists with ID "heres-an-id"
     * - "blah/blah/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": Practitioner ID by default as the
     *    prefix is unrecognized
     * - "Patient/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": actor can be a Patient
     *
     * Examples of invalid actor values:
     * - "patient/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": prefix is case-sensitive and, thus,
     *    unrecognized and the uuid is treated as a Practitioner ID
     * - "participant/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": prefix is unrecognized and CIAM treats
     *    the uuid as a Practitioner ID by default
     * - "organizations/d5efa9db-ee4c-4ce9-9756-a0f96c9148e4": CIAM does not support Organization ID as
     *    actor
     *
     * @generated from protobuf field: string actor = 1;
     */
    actor: string;
    /**
     * The list of permissions to check. The actor must have *all* of these permissions on an org
     * for that org to be in the returned list.
     *
     * @generated from protobuf field: repeated string permissions = 2;
     */
    permissions: string[];
}
/**
 * @generated from protobuf message ciam.backend.api.v1.ListAuthorizedOrganizationsResponse
 */
export interface ListAuthorizedOrganizationsResponse {
    /**
     * The list of organizations for which the actor has *all* of the specified permissions.
     *
     * @generated from protobuf field: repeated string organizations = 1;
     */
    organizations: string[];
}
/**
 * @generated from protobuf message ciam.backend.api.v1.ListIdentitiesRequest
 */
export interface ListIdentitiesRequest {
    /**
     * The type of identity to lookup.
     *
     * @generated from protobuf field: ciam.backend.api.v1.IdentityType identity_type = 1;
     */
    identityType: IdentityType;
    /**
     * The email address to lookup.
     *
     * @generated from protobuf field: string email_address = 2;
     */
    emailAddress: string;
    /**
     * Currently, this field is ignored.
     * TODO(PHP-52104): Filter identities with the email address only in this connection. If unset,
     * return matching identities across all connections.
     *
     * @generated from protobuf field: string connection = 3;
     */
    connection: string;
}
/**
 * Information about a single CIAM user record.
 *
 * @generated from protobuf message ciam.backend.api.v1.IdentityInfo
 */
export interface IdentityInfo {
    /**
     * The Auth0 ID associated with this user.
     * This field is the source of truth for the auth0 ID.
     * ciam_identifiers will also contain the Auth0 ID as one of its entries.
     *
     * @generated from protobuf field: string auth0_id = 1;
     */
    auth0Id: string;
    /**
     * The FHIR Person ID associated with this user.
     *
     * @generated from protobuf field: string person_id = 2;
     */
    personId: string;
    /**
     * The auth connection for this user. For first party accounts this will be "verily-professional"
     * or "verily-participant"; for external IdPs this will be the name of the IdP connection.
     *
     * @generated from protobuf field: string connection = 3;
     */
    connection: string;
    /**
     * Whether the user is able to login. Users who have never set their password or have been
     * explicitly disabled have active = false.
     *
     * @generated from protobuf field: bool active = 4;
     */
    active: boolean;
    /**
     * The user's preferred locale. For new users, it's whatever value was passed to CreateIdentity.
     * Subsequently this will reflect the user's browser preference the last time the user logged in
     * (i.e. we update this value with every successful login).
     * For UpdateIdentity, this field is ignored if it's empty (i.e. the user's locale will not
     * change).
     *
     * @generated from protobuf field: string locale = 5;
     */
    locale: string;
    /**
     * All ciam_identifiers need to be added to the corresponding FHIR Person during account creation.
     * May contain username (for veeps due to SSO), so not logging this field.
     *
     * @generated from protobuf field: repeated google.fhir.r4.core.Identifier ciam_identifiers = 6;
     */
    ciamIdentifiers: Identifier[];
}
/**
 * @generated from protobuf message ciam.backend.api.v1.ListIdentitiesResponse
 */
export interface ListIdentitiesResponse {
    /**
     * @generated from protobuf field: repeated ciam.backend.api.v1.IdentityInfo identities = 1;
     */
    identities: IdentityInfo[];
}
/**
 * @generated from protobuf message ciam.backend.api.v1.EmailParams
 */
export interface EmailParams {
    /**
     * Deprecated: Please use the subject and body field below
     * The name or ID of the email template to use.
     *
     * @generated from protobuf field: string email_template = 1;
     */
    emailTemplate: string;
    /**
     * Deprecated: Please use the subject and body field below
     * The substitutions to make in the email template.
     *
     * @generated from protobuf field: map<string, string> substitutions = 2;
     */
    substitutions: {
        [key: string]: string;
    };
    /**
     * NOTE: The email_template and substitutions are being deprecated and
     * mutually exclusive with the subject and body field
     * The subject of the email
     *
     * @generated from protobuf field: string subject = 3;
     */
    subject: string;
    /**
     * The body of the email with a single {{.password_reset_url}} template variable
     * NOTE:The body should be a valid HTML template. Otherwise results in a malformed email
     *
     * @generated from protobuf field: string body = 4;
     */
    body: string;
    /**
     * The user's preferred locale (2 letter language code), used to select the correct version of the
     * email to send. If not set (or set to a non-supported value), we default to 'en'.
     *
     * @generated from protobuf field: string locale = 5;
     */
    locale: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.CreateIdentityRequest
 */
export interface CreateIdentityRequest {
    /**
     * The type of identity to create.
     *
     * @generated from protobuf field: ciam.backend.api.v1.IdentityType identity_type = 1;
     */
    identityType: IdentityType;
    /**
     * The email address for the new identity.
     *
     * @generated from protobuf field: string email_address = 2;
     */
    emailAddress: string;
    /**
     * The ID of the FHIR Person resource that's associated with the new identity.
     *
     * @generated from protobuf field: string person_id = 3;
     */
    personId: string;
    /**
     * The name of the application to link to after the user sets their password.
     * If not set, or if the name specified does not match a known application, after the user resets
     * their password, they will reach a confirmation page with no links back to the application.
     *
     * @generated from protobuf field: string application_name = 4;
     */
    applicationName: string;
    /**
     * If set, the response will contain a password reset URL. This should only be set when the
     * caller will handle delivering the link to the user in some other manner, e.g. displaying it
     * directly. The caller is responsible for ensuring this is provided only to the owner of the
     * email address.
     *
     * @generated from protobuf field: bool return_reset_url = 5;
     */
    returnResetUrl: boolean;
    /**
     * If set, CIAM will send an email to the newly created identity using these parameters.
     * This is the same as calling SendPasswordResetEmail after a successful CreateIdentity request.
     *
     * @generated from protobuf field: ciam.backend.api.v1.EmailParams email_params = 6;
     */
    emailParams?: EmailParams;
    /**
     * The preferred locale for the user. If empty or an unsupported locale, defaults to en.
     * Callers should set both this and the one in the email_params as they are used for different
     * contexts and eventually email handling ought to go through a Verily-wide mail service (i.e.
     * we expect email_params to go away but still need this value to initialize the Auth0 record.)
     *
     * @generated from protobuf field: string locale = 7;
     */
    locale: string;
    /**
     * The type of the connection for the user. If empty, it will default to database. CIAM will
     * support "database" and "verily-sso" for v1. An unsupported connection type
     * will result in an invalid argument error.
     *
     * @generated from protobuf field: string connection = 8;
     */
    connection: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.CreateIdentityResponse
 */
export interface CreateIdentityResponse {
    /**
     * @generated from protobuf field: ciam.backend.api.v1.IdentityInfo identity = 1;
     */
    identity?: IdentityInfo;
    /**
     * @generated from protobuf field: string password_reset_url = 2;
     */
    passwordResetUrl: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.SendPasswordResetEmailRequest
 */
export interface SendPasswordResetEmailRequest {
    /**
     * The type of identity whose password to reset.
     *
     * @generated from protobuf field: ciam.backend.api.v1.IdentityType identity_type = 1;
     */
    identityType: IdentityType;
    /**
     * The Auth0 ID for the identity to send the email to.
     *
     * @generated from protobuf field: string auth0_id = 2;
     */
    auth0Id: string;
    /**
     * The name of the application to link to after the user sets their password.
     * If not set, or if the name specified does not match a known application, after the user resets
     * their password, they will reach a confirmation page with no links back to the application.
     *
     * @generated from protobuf field: string application_name = 3;
     */
    applicationName: string;
    /**
     * Specification for the email to send.
     *
     * @generated from protobuf field: ciam.backend.api.v1.EmailParams email_params = 4;
     */
    emailParams?: EmailParams;
    /**
     * The email address to send the reset message to. If this is not set, CIAM will look it up.
     * The caller must be certain this is the correct address for this Identity. When in doubt, leave
     * this blank!
     * (This is effectively an optimization to avoid the Auth0 API call needed to look up the email
     * address, for use e.g. when the caller of CreateIdentity needs to do additional work in order to
     * correctly set up email_params and therefore can't request it as part of that call.)
     *
     * @generated from protobuf field: string email_address = 5;
     */
    emailAddress: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.SendPasswordResetEmailResponse
 */
export interface SendPasswordResetEmailResponse {
}
/**
 * @generated from protobuf message ciam.backend.api.v1.UpdateIdentityRequest
 */
export interface UpdateIdentityRequest {
    /**
     * The type of identity to update.
     *
     * @generated from protobuf field: ciam.backend.api.v1.IdentityType identity_type = 1;
     */
    identityType: IdentityType;
    /**
     * The updated values for the identity.
     * Note that this call ignores person_id and connection as those are not mutable.
     * It also only updates active status if update_active (below) is set.
     *
     * @generated from protobuf field: ciam.backend.api.v1.IdentityInfo identity_info = 2;
     */
    identityInfo?: IdentityInfo;
    /**
     * If set, indicates that identity_info.active should be written to the user record.
     * Otherwise, callers would be required to know the user's active status to avoid clobbering it.
     *
     * @generated from protobuf field: bool update_active = 3;
     */
    updateActive: boolean;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.UpdateIdentityResponse
 */
export interface UpdateIdentityResponse {
}
/**
 * @generated from protobuf message ciam.backend.api.v1.IssueTokenRequest
 */
export interface IssueTokenRequest {
    /**
     * Indicates whether the caller wants a Professional or Me token.
     *
     * @generated from protobuf field: ciam.backend.api.v1.IdentityType identity_type = 1;
     */
    identityType: IdentityType;
    /**
     * The lifetime for this token in minutes
     *
     * @generated from protobuf field: int32 token_lifetime = 2;
     */
    tokenLifetime: number;
    /**
     * FHIR Person ID to be linked with this token. This is temporary
     * until Person ID can be fetched from FHIR store.
     *
     * @generated from protobuf field: string person_id = 3;
     */
    personId: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.IssueTokenResponse
 */
export interface IssueTokenResponse {
    /**
     * uuid that BFF should use to create temporal FHIR person with
     *
     * @generated from protobuf field: string ciam_id = 1;
     */
    ciamId: string;
    /**
     * @generated from protobuf field: string access_token = 2;
     */
    accessToken: string;
    /**
     * The expiration time of the access_token in UTC.
     *
     * @generated from protobuf field: google.protobuf.Timestamp expiration_time = 3;
     */
    expirationTime?: Timestamp;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.GetPasswordChangeURLRequest
 */
export interface GetPasswordChangeURLRequest {
    /**
     * The name of the application to link to after the user sets their password.
     * If not set, or if the name specified does not match a known application, after the user resets
     * their password, they will reach a confirmation page with no links back to the application.
     *
     * @generated from protobuf field: string application_name = 1;
     */
    applicationName: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.GetPasswordChangeURLResponse
 */
export interface GetPasswordChangeURLResponse {
    /**
     * The URL to redirect to in order for the user to change their password.
     *
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.DeleteAuth0RecordsRequest
 */
export interface DeleteAuth0RecordsRequest {
    /**
     * A FHIR Identifier of a FHIR Person that is being deleted.
     *
     * @generated from protobuf field: google.fhir.r4.core.Identifier identifier = 1;
     */
    identifier?: Identifier;
}
/**
 * @generated from protobuf message ciam.backend.api.v1.DeleteAuth0RecordsResponse
 */
export interface DeleteAuth0RecordsResponse {
    /**
     * Outcome of the deletion request.
     *
     * @generated from protobuf field: ciam.backend.api.v1.DeletionOutcome outcome = 1;
     */
    outcome: DeletionOutcome;
}
/**
 * CIAM handles both Professional and Participant identities. All identities (including the current
 * user's) must specify its type.
 *
 * @generated from protobuf enum ciam.backend.api.v1.IdentityType
 */
export enum IdentityType {
    /**
     * @generated from protobuf enum value: IDENTITY_TYPE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: IDENTITY_TYPE_PROFESSIONAL = 1;
     */
    PROFESSIONAL = 1,
    /**
     * @generated from protobuf enum value: IDENTITY_TYPE_PARTICIPANT = 2;
     */
    PARTICIPANT = 2
}
/**
 * Indicates the source of this token
 * For Auth0 issued tokens: this is which tenant issued it (and corresponds to the IdentityType)
 * For ciam-be issued tokens: this is what kind of token it is (currently only PSATs).
 *
 * @generated from protobuf enum ciam.backend.api.v1.TokenType
 */
export enum TokenType {
    /**
     * @generated from protobuf enum value: TOKEN_TYPE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: TOKEN_TYPE_AUTH0_PROFESSIONAL = 1;
     */
    AUTH0_PROFESSIONAL = 1,
    /**
     * @generated from protobuf enum value: TOKEN_TYPE_AUTH0_PARTICIPANT = 2;
     */
    AUTH0_PARTICIPANT = 2,
    /**
     * @generated from protobuf enum value: TOKEN_TYPE_PSAT = 3;
     */
    PSAT = 3
}
/**
 * @generated from protobuf enum ciam.backend.api.v1.AuthorizationDecision
 */
export enum AuthorizationDecision {
    /**
     * Not set. Read response code instead to get the decision.
     *
     * @generated from protobuf enum value: AUTHORIZATION_DECISION_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * grpc status code OK
     *
     * @generated from protobuf enum value: AUTHORIZATION_DECISION_OK = 1;
     */
    OK = 1,
    /**
     * grpc status code Unauthenticated
     *
     * @generated from protobuf enum value: AUTHORIZATION_DECISION_UNAUTHENTICATED = 2;
     */
    UNAUTHENTICATED = 2,
    /**
     * grpc status code PermissionDenied
     *
     * It's possible that other errors may be returned that are not listed here.
     *
     * @generated from protobuf enum value: AUTHORIZATION_DECISION_PERMISSION_DENIED = 3;
     */
    PERMISSION_DENIED = 3
}
/**
 * Result of deletion request:
 *
 * @generated from protobuf enum ciam.backend.api.v1.DeletionOutcome
 */
export enum DeletionOutcome {
    /**
     * @generated from protobuf enum value: DELETION_OUTCOME_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * User was successfully deleted
     *
     * @generated from protobuf enum value: DELETION_OUTCOME_SUCCESS = 1;
     */
    SUCCESS = 1,
    /**
     * Identifier that CIAM does not own, and thus no operation is necessary
     *
     * @generated from protobuf enum value: DELETION_OUTCOME_IDENTIFIER_NOT_RECOGNIZED = 2;
     */
    IDENTIFIER_NOT_RECOGNIZED = 2,
    /**
     * The Auth0 ID from the passed identifier does not exist in Auth0
     *
     * @generated from protobuf enum value: DELETION_OUTCOME_AUTH0_USER_NOT_FOUND = 3;
     */
    AUTH0_USER_NOT_FOUND = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class DecisionDetails$Type extends MessageType<DecisionDetails> {
    constructor() {
        super("ciam.backend.api.v1.DecisionDetails", [
            { no: 1, name: "decision", kind: "enum", T: () => ["ciam.backend.api.v1.AuthorizationDecision", AuthorizationDecision, "AUTHORIZATION_DECISION_"] },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "actor_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "target_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DecisionDetails>): DecisionDetails {
        const message = { decision: 0, message: "", actorId: "", targetId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DecisionDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecisionDetails): DecisionDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ciam.backend.api.v1.AuthorizationDecision decision */ 1:
                    message.decision = reader.int32();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                case /* string actor_id */ 3:
                    message.actorId = reader.string();
                    break;
                case /* string target_id */ 4:
                    message.targetId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecisionDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ciam.backend.api.v1.AuthorizationDecision decision = 1; */
        if (message.decision !== 0)
            writer.tag(1, WireType.Varint).int32(message.decision);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        /* string actor_id = 3; */
        if (message.actorId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.actorId);
        /* string target_id = 4; */
        if (message.targetId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.targetId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.DecisionDetails
 */
export const DecisionDetails = new DecisionDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInfo$Type extends MessageType<UserInfo> {
    constructor() {
        super("ciam.backend.api.v1.UserInfo", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "person_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ciam_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "token_type", kind: "enum", T: () => ["ciam.backend.api.v1.TokenType", TokenType, "TOKEN_TYPE_"] },
            { no: 5, name: "phone_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "connection", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "patient_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "practitioner_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserInfo>): UserInfo {
        const message = { email: "", personId: "", ciamId: "", tokenType: 0, phoneNumber: "", connection: "", patientIds: [], practitionerIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserInfo): UserInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* string person_id */ 2:
                    message.personId = reader.string();
                    break;
                case /* string ciam_id */ 3:
                    message.ciamId = reader.string();
                    break;
                case /* ciam.backend.api.v1.TokenType token_type */ 4:
                    message.tokenType = reader.int32();
                    break;
                case /* string phone_number */ 5:
                    message.phoneNumber = reader.string();
                    break;
                case /* string connection */ 6:
                    message.connection = reader.string();
                    break;
                case /* repeated string patient_ids */ 7:
                    message.patientIds.push(reader.string());
                    break;
                case /* repeated string practitioner_ids */ 8:
                    message.practitionerIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* string person_id = 2; */
        if (message.personId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.personId);
        /* string ciam_id = 3; */
        if (message.ciamId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.ciamId);
        /* ciam.backend.api.v1.TokenType token_type = 4; */
        if (message.tokenType !== 0)
            writer.tag(4, WireType.Varint).int32(message.tokenType);
        /* string phone_number = 5; */
        if (message.phoneNumber !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.phoneNumber);
        /* string connection = 6; */
        if (message.connection !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.connection);
        /* repeated string patient_ids = 7; */
        for (let i = 0; i < message.patientIds.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.patientIds[i]);
        /* repeated string practitioner_ids = 8; */
        for (let i = 0; i < message.practitionerIds.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.practitionerIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.UserInfo
 */
export const UserInfo = new UserInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthorizationInfo$Type extends MessageType<AuthorizationInfo> {
    constructor() {
        super("ciam.backend.api.v1.AuthorizationInfo", [
            { no: 1, name: "target_organization", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthorizationInfo>): AuthorizationInfo {
        const message = { targetOrganization: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AuthorizationInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthorizationInfo): AuthorizationInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string target_organization */ 1:
                    message.targetOrganization = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthorizationInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string target_organization = 1; */
        if (message.targetOrganization !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.targetOrganization);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.AuthorizationInfo
 */
export const AuthorizationInfo = new AuthorizationInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Auth0Config$Type extends MessageType<Auth0Config> {
    constructor() {
        super("ciam.backend.api.v1.Auth0Config", [
            { no: 1, name: "domain", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "client_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "audience", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Auth0Config>): Auth0Config {
        const message = { domain: "", clientId: "", audience: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Auth0Config>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Auth0Config): Auth0Config {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string domain */ 1:
                    message.domain = reader.string();
                    break;
                case /* string client_id */ 2:
                    message.clientId = reader.string();
                    break;
                case /* string audience */ 3:
                    message.audience = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Auth0Config, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string domain = 1; */
        if (message.domain !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.domain);
        /* string client_id = 2; */
        if (message.clientId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.clientId);
        /* string audience = 3; */
        if (message.audience !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.audience);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.Auth0Config
 */
export const Auth0Config = new Auth0Config$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAuthorizationRequest$Type extends MessageType<CheckAuthorizationRequest> {
    constructor() {
        super("ciam.backend.api.v1.CheckAuthorizationRequest", [
            { no: 1, name: "permissions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "inputs", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 3, name: "validate_id_token", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "skip_actor_person_match", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "reauth", kind: "message", T: () => Reauthentication },
            { no: 6, name: "accept_psat", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "return_metadata_in_response_message", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "app_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "user_info_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CheckAuthorizationRequest>): CheckAuthorizationRequest {
        const message = { permissions: [], inputs: {}, validateIdToken: false, skipActorPersonMatch: false, acceptPsat: false, returnMetadataInResponseMessage: false, appName: "", userInfoOnly: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckAuthorizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAuthorizationRequest): CheckAuthorizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string permissions */ 1:
                    message.permissions.push(reader.string());
                    break;
                case /* map<string, string> inputs */ 2:
                    this.binaryReadMap2(message.inputs, reader, options);
                    break;
                case /* bool validate_id_token */ 3:
                    message.validateIdToken = reader.bool();
                    break;
                case /* bool skip_actor_person_match */ 4:
                    message.skipActorPersonMatch = reader.bool();
                    break;
                case /* ciam.backend.api.v1.Reauthentication reauth */ 5:
                    message.reauth = Reauthentication.internalBinaryRead(reader, reader.uint32(), options, message.reauth);
                    break;
                case /* bool accept_psat */ 6:
                    message.acceptPsat = reader.bool();
                    break;
                case /* bool return_metadata_in_response_message */ 7:
                    message.returnMetadataInResponseMessage = reader.bool();
                    break;
                case /* string app_name */ 8:
                    message.appName = reader.string();
                    break;
                case /* bool user_info_only */ 9:
                    message.userInfoOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: CheckAuthorizationRequest["inputs"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CheckAuthorizationRequest["inputs"] | undefined, val: CheckAuthorizationRequest["inputs"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ciam.backend.api.v1.CheckAuthorizationRequest.inputs");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: CheckAuthorizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string permissions = 1; */
        for (let i = 0; i < message.permissions.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.permissions[i]);
        /* map<string, string> inputs = 2; */
        for (let k of Object.keys(message.inputs))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.inputs[k]).join();
        /* bool validate_id_token = 3; */
        if (message.validateIdToken !== false)
            writer.tag(3, WireType.Varint).bool(message.validateIdToken);
        /* bool skip_actor_person_match = 4; */
        if (message.skipActorPersonMatch !== false)
            writer.tag(4, WireType.Varint).bool(message.skipActorPersonMatch);
        /* ciam.backend.api.v1.Reauthentication reauth = 5; */
        if (message.reauth)
            Reauthentication.internalBinaryWrite(message.reauth, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bool accept_psat = 6; */
        if (message.acceptPsat !== false)
            writer.tag(6, WireType.Varint).bool(message.acceptPsat);
        /* bool return_metadata_in_response_message = 7; */
        if (message.returnMetadataInResponseMessage !== false)
            writer.tag(7, WireType.Varint).bool(message.returnMetadataInResponseMessage);
        /* string app_name = 8; */
        if (message.appName !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.appName);
        /* bool user_info_only = 9; */
        if (message.userInfoOnly !== false)
            writer.tag(9, WireType.Varint).bool(message.userInfoOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.CheckAuthorizationRequest
 */
export const CheckAuthorizationRequest = new CheckAuthorizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Reauthentication$Type extends MessageType<Reauthentication> {
    constructor() {
        super("ciam.backend.api.v1.Reauthentication", [
            { no: 1, name: "operation", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "object_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Reauthentication>): Reauthentication {
        const message = { operation: "", objectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Reauthentication>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Reauthentication): Reauthentication {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string operation */ 1:
                    message.operation = reader.string();
                    break;
                case /* string object_id */ 2:
                    message.objectId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Reauthentication, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string operation = 1; */
        if (message.operation !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.operation);
        /* string object_id = 2; */
        if (message.objectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.objectId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.Reauthentication
 */
export const Reauthentication = new Reauthentication$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAuthorizationResponse$Type extends MessageType<CheckAuthorizationResponse> {
    constructor() {
        super("ciam.backend.api.v1.CheckAuthorizationResponse", [
            { no: 1, name: "is_valid_id_token", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "id_token_invalid_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "auth0_config", kind: "message", T: () => Auth0Config },
            { no: 4, name: "user_info", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserInfo },
            { no: 5, name: "additional_query_params", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 6, name: "decision_details", kind: "message", T: () => DecisionDetails },
            { no: 7, name: "authorization_info", kind: "message", T: () => AuthorizationInfo }
        ]);
    }
    create(value?: PartialMessage<CheckAuthorizationResponse>): CheckAuthorizationResponse {
        const message = { isValidIdToken: false, idTokenInvalidReason: "", userInfo: [], additionalQueryParams: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckAuthorizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAuthorizationResponse): CheckAuthorizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool is_valid_id_token */ 1:
                    message.isValidIdToken = reader.bool();
                    break;
                case /* string id_token_invalid_reason */ 2:
                    message.idTokenInvalidReason = reader.string();
                    break;
                case /* ciam.backend.api.v1.Auth0Config auth0_config */ 3:
                    message.auth0Config = Auth0Config.internalBinaryRead(reader, reader.uint32(), options, message.auth0Config);
                    break;
                case /* repeated ciam.backend.api.v1.UserInfo user_info */ 4:
                    message.userInfo.push(UserInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, string> additional_query_params */ 5:
                    this.binaryReadMap5(message.additionalQueryParams, reader, options);
                    break;
                case /* ciam.backend.api.v1.DecisionDetails decision_details */ 6:
                    message.decisionDetails = DecisionDetails.internalBinaryRead(reader, reader.uint32(), options, message.decisionDetails);
                    break;
                case /* ciam.backend.api.v1.AuthorizationInfo authorization_info */ 7:
                    message.authorizationInfo = AuthorizationInfo.internalBinaryRead(reader, reader.uint32(), options, message.authorizationInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: CheckAuthorizationResponse["additionalQueryParams"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CheckAuthorizationResponse["additionalQueryParams"] | undefined, val: CheckAuthorizationResponse["additionalQueryParams"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ciam.backend.api.v1.CheckAuthorizationResponse.additional_query_params");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: CheckAuthorizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool is_valid_id_token = 1; */
        if (message.isValidIdToken !== false)
            writer.tag(1, WireType.Varint).bool(message.isValidIdToken);
        /* string id_token_invalid_reason = 2; */
        if (message.idTokenInvalidReason !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.idTokenInvalidReason);
        /* ciam.backend.api.v1.Auth0Config auth0_config = 3; */
        if (message.auth0Config)
            Auth0Config.internalBinaryWrite(message.auth0Config, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated ciam.backend.api.v1.UserInfo user_info = 4; */
        for (let i = 0; i < message.userInfo.length; i++)
            UserInfo.internalBinaryWrite(message.userInfo[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* map<string, string> additional_query_params = 5; */
        for (let k of Object.keys(message.additionalQueryParams))
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.additionalQueryParams[k]).join();
        /* ciam.backend.api.v1.DecisionDetails decision_details = 6; */
        if (message.decisionDetails)
            DecisionDetails.internalBinaryWrite(message.decisionDetails, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* ciam.backend.api.v1.AuthorizationInfo authorization_info = 7; */
        if (message.authorizationInfo)
            AuthorizationInfo.internalBinaryWrite(message.authorizationInfo, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.CheckAuthorizationResponse
 */
export const CheckAuthorizationResponse = new CheckAuthorizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCheckAuthorizationRequest$Type extends MessageType<BatchCheckAuthorizationRequest> {
    constructor() {
        super("ciam.backend.api.v1.BatchCheckAuthorizationRequest", [
            { no: 1, name: "permissions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "actor", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "target", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "targets", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "use_rbac", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BatchCheckAuthorizationRequest>): BatchCheckAuthorizationRequest {
        const message = { permissions: [], actor: "", target: "", targets: [], useRbac: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchCheckAuthorizationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchCheckAuthorizationRequest): BatchCheckAuthorizationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string permissions */ 1:
                    message.permissions.push(reader.string());
                    break;
                case /* string actor */ 2:
                    message.actor = reader.string();
                    break;
                case /* string target */ 3:
                    message.target = reader.string();
                    break;
                case /* repeated string targets */ 5:
                    message.targets.push(reader.string());
                    break;
                case /* bool use_rbac */ 4:
                    message.useRbac = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchCheckAuthorizationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string permissions = 1; */
        for (let i = 0; i < message.permissions.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.permissions[i]);
        /* string actor = 2; */
        if (message.actor !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.actor);
        /* string target = 3; */
        if (message.target !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.target);
        /* repeated string targets = 5; */
        for (let i = 0; i < message.targets.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.targets[i]);
        /* bool use_rbac = 4; */
        if (message.useRbac !== false)
            writer.tag(4, WireType.Varint).bool(message.useRbac);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.BatchCheckAuthorizationRequest
 */
export const BatchCheckAuthorizationRequest = new BatchCheckAuthorizationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchCheckAuthorizationResponse$Type extends MessageType<BatchCheckAuthorizationResponse> {
    constructor() {
        super("ciam.backend.api.v1.BatchCheckAuthorizationResponse", [
            { no: 1, name: "permissions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "target_permissions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => PermissionList } }
        ]);
    }
    create(value?: PartialMessage<BatchCheckAuthorizationResponse>): BatchCheckAuthorizationResponse {
        const message = { permissions: [], targetPermissions: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchCheckAuthorizationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchCheckAuthorizationResponse): BatchCheckAuthorizationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string permissions */ 1:
                    message.permissions.push(reader.string());
                    break;
                case /* map<string, ciam.backend.api.v1.PermissionList> target_permissions */ 2:
                    this.binaryReadMap2(message.targetPermissions, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: BatchCheckAuthorizationResponse["targetPermissions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BatchCheckAuthorizationResponse["targetPermissions"] | undefined, val: BatchCheckAuthorizationResponse["targetPermissions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = PermissionList.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ciam.backend.api.v1.BatchCheckAuthorizationResponse.target_permissions");
            }
        }
        map[key ?? ""] = val ?? PermissionList.create();
    }
    internalBinaryWrite(message: BatchCheckAuthorizationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string permissions = 1; */
        for (let i = 0; i < message.permissions.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.permissions[i]);
        /* map<string, ciam.backend.api.v1.PermissionList> target_permissions = 2; */
        for (let k of Object.keys(message.targetPermissions)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PermissionList.internalBinaryWrite(message.targetPermissions[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.BatchCheckAuthorizationResponse
 */
export const BatchCheckAuthorizationResponse = new BatchCheckAuthorizationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PermissionList$Type extends MessageType<PermissionList> {
    constructor() {
        super("ciam.backend.api.v1.PermissionList", [
            { no: 1, name: "permissions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PermissionList>): PermissionList {
        const message = { permissions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PermissionList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PermissionList): PermissionList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string permissions */ 1:
                    message.permissions.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PermissionList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string permissions = 1; */
        for (let i = 0; i < message.permissions.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.permissions[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.PermissionList
 */
export const PermissionList = new PermissionList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssignedRoleFeaturesRequest$Type extends MessageType<GetAssignedRoleFeaturesRequest> {
    constructor() {
        super("ciam.backend.api.v1.GetAssignedRoleFeaturesRequest", [
            { no: 1, name: "actor", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "target", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAssignedRoleFeaturesRequest>): GetAssignedRoleFeaturesRequest {
        const message = { actor: "", target: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssignedRoleFeaturesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssignedRoleFeaturesRequest): GetAssignedRoleFeaturesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string actor */ 1:
                    message.actor = reader.string();
                    break;
                case /* string target */ 2:
                    message.target = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAssignedRoleFeaturesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string actor = 1; */
        if (message.actor !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actor);
        /* string target = 2; */
        if (message.target !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.target);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.GetAssignedRoleFeaturesRequest
 */
export const GetAssignedRoleFeaturesRequest = new GetAssignedRoleFeaturesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssignedRoleFeaturesResponse$Type extends MessageType<GetAssignedRoleFeaturesResponse> {
    constructor() {
        super("ciam.backend.api.v1.GetAssignedRoleFeaturesResponse", [
            { no: 1, name: "role_features", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAssignedRoleFeaturesResponse>): GetAssignedRoleFeaturesResponse {
        const message = { roleFeatures: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssignedRoleFeaturesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssignedRoleFeaturesResponse): GetAssignedRoleFeaturesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string role_features */ 1:
                    message.roleFeatures.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAssignedRoleFeaturesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string role_features = 1; */
        for (let i = 0; i < message.roleFeatures.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.roleFeatures[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.GetAssignedRoleFeaturesResponse
 */
export const GetAssignedRoleFeaturesResponse = new GetAssignedRoleFeaturesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAuthorizedOrganizationsRequest$Type extends MessageType<ListAuthorizedOrganizationsRequest> {
    constructor() {
        super("ciam.backend.api.v1.ListAuthorizedOrganizationsRequest", [
            { no: 1, name: "actor", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "permissions", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<ListAuthorizedOrganizationsRequest>): ListAuthorizedOrganizationsRequest {
        const message = { actor: "", permissions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAuthorizedOrganizationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAuthorizedOrganizationsRequest): ListAuthorizedOrganizationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string actor */ 1:
                    message.actor = reader.string();
                    break;
                case /* repeated string permissions */ 2:
                    message.permissions.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAuthorizedOrganizationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string actor = 1; */
        if (message.actor !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.actor);
        /* repeated string permissions = 2; */
        for (let i = 0; i < message.permissions.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.permissions[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.ListAuthorizedOrganizationsRequest
 */
export const ListAuthorizedOrganizationsRequest = new ListAuthorizedOrganizationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAuthorizedOrganizationsResponse$Type extends MessageType<ListAuthorizedOrganizationsResponse> {
    constructor() {
        super("ciam.backend.api.v1.ListAuthorizedOrganizationsResponse", [
            { no: 1, name: "organizations", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListAuthorizedOrganizationsResponse>): ListAuthorizedOrganizationsResponse {
        const message = { organizations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListAuthorizedOrganizationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAuthorizedOrganizationsResponse): ListAuthorizedOrganizationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string organizations */ 1:
                    message.organizations.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAuthorizedOrganizationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string organizations = 1; */
        for (let i = 0; i < message.organizations.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.organizations[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.ListAuthorizedOrganizationsResponse
 */
export const ListAuthorizedOrganizationsResponse = new ListAuthorizedOrganizationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIdentitiesRequest$Type extends MessageType<ListIdentitiesRequest> {
    constructor() {
        super("ciam.backend.api.v1.ListIdentitiesRequest", [
            { no: 1, name: "identity_type", kind: "enum", T: () => ["ciam.backend.api.v1.IdentityType", IdentityType, "IDENTITY_TYPE_"], options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "email_address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "connection", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListIdentitiesRequest>): ListIdentitiesRequest {
        const message = { identityType: 0, emailAddress: "", connection: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListIdentitiesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIdentitiesRequest): ListIdentitiesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ciam.backend.api.v1.IdentityType identity_type */ 1:
                    message.identityType = reader.int32();
                    break;
                case /* string email_address */ 2:
                    message.emailAddress = reader.string();
                    break;
                case /* string connection */ 3:
                    message.connection = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListIdentitiesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ciam.backend.api.v1.IdentityType identity_type = 1; */
        if (message.identityType !== 0)
            writer.tag(1, WireType.Varint).int32(message.identityType);
        /* string email_address = 2; */
        if (message.emailAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.emailAddress);
        /* string connection = 3; */
        if (message.connection !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.connection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.ListIdentitiesRequest
 */
export const ListIdentitiesRequest = new ListIdentitiesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IdentityInfo$Type extends MessageType<IdentityInfo> {
    constructor() {
        super("ciam.backend.api.v1.IdentityInfo", [
            { no: 1, name: "auth0_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "person_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "connection", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "ciam_identifiers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Identifier }
        ]);
    }
    create(value?: PartialMessage<IdentityInfo>): IdentityInfo {
        const message = { auth0Id: "", personId: "", connection: "", active: false, locale: "", ciamIdentifiers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IdentityInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IdentityInfo): IdentityInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string auth0_id */ 1:
                    message.auth0Id = reader.string();
                    break;
                case /* string person_id */ 2:
                    message.personId = reader.string();
                    break;
                case /* string connection */ 3:
                    message.connection = reader.string();
                    break;
                case /* bool active */ 4:
                    message.active = reader.bool();
                    break;
                case /* string locale */ 5:
                    message.locale = reader.string();
                    break;
                case /* repeated google.fhir.r4.core.Identifier ciam_identifiers */ 6:
                    message.ciamIdentifiers.push(Identifier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IdentityInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string auth0_id = 1; */
        if (message.auth0Id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.auth0Id);
        /* string person_id = 2; */
        if (message.personId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.personId);
        /* string connection = 3; */
        if (message.connection !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.connection);
        /* bool active = 4; */
        if (message.active !== false)
            writer.tag(4, WireType.Varint).bool(message.active);
        /* string locale = 5; */
        if (message.locale !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.locale);
        /* repeated google.fhir.r4.core.Identifier ciam_identifiers = 6; */
        for (let i = 0; i < message.ciamIdentifiers.length; i++)
            Identifier.internalBinaryWrite(message.ciamIdentifiers[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.IdentityInfo
 */
export const IdentityInfo = new IdentityInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListIdentitiesResponse$Type extends MessageType<ListIdentitiesResponse> {
    constructor() {
        super("ciam.backend.api.v1.ListIdentitiesResponse", [
            { no: 1, name: "identities", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IdentityInfo }
        ]);
    }
    create(value?: PartialMessage<ListIdentitiesResponse>): ListIdentitiesResponse {
        const message = { identities: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListIdentitiesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListIdentitiesResponse): ListIdentitiesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ciam.backend.api.v1.IdentityInfo identities */ 1:
                    message.identities.push(IdentityInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListIdentitiesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ciam.backend.api.v1.IdentityInfo identities = 1; */
        for (let i = 0; i < message.identities.length; i++)
            IdentityInfo.internalBinaryWrite(message.identities[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.ListIdentitiesResponse
 */
export const ListIdentitiesResponse = new ListIdentitiesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EmailParams$Type extends MessageType<EmailParams> {
    constructor() {
        super("ciam.backend.api.v1.EmailParams", [
            { no: 1, name: "email_template", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "substitutions", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ }, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EmailParams>): EmailParams {
        const message = { emailTemplate: "", substitutions: {}, subject: "", body: "", locale: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EmailParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EmailParams): EmailParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email_template */ 1:
                    message.emailTemplate = reader.string();
                    break;
                case /* map<string, string> substitutions */ 2:
                    this.binaryReadMap2(message.substitutions, reader, options);
                    break;
                case /* string subject */ 3:
                    message.subject = reader.string();
                    break;
                case /* string body */ 4:
                    message.body = reader.string();
                    break;
                case /* string locale */ 5:
                    message.locale = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: EmailParams["substitutions"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof EmailParams["substitutions"] | undefined, val: EmailParams["substitutions"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field ciam.backend.api.v1.EmailParams.substitutions");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: EmailParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email_template = 1; */
        if (message.emailTemplate !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.emailTemplate);
        /* map<string, string> substitutions = 2; */
        for (let k of Object.keys(message.substitutions))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.substitutions[k]).join();
        /* string subject = 3; */
        if (message.subject !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.subject);
        /* string body = 4; */
        if (message.body !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.body);
        /* string locale = 5; */
        if (message.locale !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.locale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.EmailParams
 */
export const EmailParams = new EmailParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateIdentityRequest$Type extends MessageType<CreateIdentityRequest> {
    constructor() {
        super("ciam.backend.api.v1.CreateIdentityRequest", [
            { no: 1, name: "identity_type", kind: "enum", T: () => ["ciam.backend.api.v1.IdentityType", IdentityType, "IDENTITY_TYPE_"], options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "email_address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "person_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 4, name: "application_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 5, name: "return_reset_url", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "email_params", kind: "message", T: () => EmailParams },
            { no: 7, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "connection", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateIdentityRequest>): CreateIdentityRequest {
        const message = { identityType: 0, emailAddress: "", personId: "", applicationName: "", returnResetUrl: false, locale: "", connection: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateIdentityRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateIdentityRequest): CreateIdentityRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ciam.backend.api.v1.IdentityType identity_type */ 1:
                    message.identityType = reader.int32();
                    break;
                case /* string email_address */ 2:
                    message.emailAddress = reader.string();
                    break;
                case /* string person_id */ 3:
                    message.personId = reader.string();
                    break;
                case /* string application_name */ 4:
                    message.applicationName = reader.string();
                    break;
                case /* bool return_reset_url */ 5:
                    message.returnResetUrl = reader.bool();
                    break;
                case /* ciam.backend.api.v1.EmailParams email_params */ 6:
                    message.emailParams = EmailParams.internalBinaryRead(reader, reader.uint32(), options, message.emailParams);
                    break;
                case /* string locale */ 7:
                    message.locale = reader.string();
                    break;
                case /* string connection */ 8:
                    message.connection = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateIdentityRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ciam.backend.api.v1.IdentityType identity_type = 1; */
        if (message.identityType !== 0)
            writer.tag(1, WireType.Varint).int32(message.identityType);
        /* string email_address = 2; */
        if (message.emailAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.emailAddress);
        /* string person_id = 3; */
        if (message.personId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.personId);
        /* string application_name = 4; */
        if (message.applicationName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.applicationName);
        /* bool return_reset_url = 5; */
        if (message.returnResetUrl !== false)
            writer.tag(5, WireType.Varint).bool(message.returnResetUrl);
        /* ciam.backend.api.v1.EmailParams email_params = 6; */
        if (message.emailParams)
            EmailParams.internalBinaryWrite(message.emailParams, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string locale = 7; */
        if (message.locale !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.locale);
        /* string connection = 8; */
        if (message.connection !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.connection);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.CreateIdentityRequest
 */
export const CreateIdentityRequest = new CreateIdentityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateIdentityResponse$Type extends MessageType<CreateIdentityResponse> {
    constructor() {
        super("ciam.backend.api.v1.CreateIdentityResponse", [
            { no: 1, name: "identity", kind: "message", T: () => IdentityInfo },
            { no: 2, name: "password_reset_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateIdentityResponse>): CreateIdentityResponse {
        const message = { passwordResetUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateIdentityResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateIdentityResponse): CreateIdentityResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ciam.backend.api.v1.IdentityInfo identity */ 1:
                    message.identity = IdentityInfo.internalBinaryRead(reader, reader.uint32(), options, message.identity);
                    break;
                case /* string password_reset_url */ 2:
                    message.passwordResetUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateIdentityResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ciam.backend.api.v1.IdentityInfo identity = 1; */
        if (message.identity)
            IdentityInfo.internalBinaryWrite(message.identity, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string password_reset_url = 2; */
        if (message.passwordResetUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.passwordResetUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.CreateIdentityResponse
 */
export const CreateIdentityResponse = new CreateIdentityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendPasswordResetEmailRequest$Type extends MessageType<SendPasswordResetEmailRequest> {
    constructor() {
        super("ciam.backend.api.v1.SendPasswordResetEmailRequest", [
            { no: 1, name: "identity_type", kind: "enum", T: () => ["ciam.backend.api.v1.IdentityType", IdentityType, "IDENTITY_TYPE_"], options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "auth0_id", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "application_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "email_params", kind: "message", T: () => EmailParams, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 5, name: "email_address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendPasswordResetEmailRequest>): SendPasswordResetEmailRequest {
        const message = { identityType: 0, auth0Id: "", applicationName: "", emailAddress: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendPasswordResetEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendPasswordResetEmailRequest): SendPasswordResetEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ciam.backend.api.v1.IdentityType identity_type */ 1:
                    message.identityType = reader.int32();
                    break;
                case /* string auth0_id */ 2:
                    message.auth0Id = reader.string();
                    break;
                case /* string application_name */ 3:
                    message.applicationName = reader.string();
                    break;
                case /* ciam.backend.api.v1.EmailParams email_params */ 4:
                    message.emailParams = EmailParams.internalBinaryRead(reader, reader.uint32(), options, message.emailParams);
                    break;
                case /* string email_address */ 5:
                    message.emailAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendPasswordResetEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ciam.backend.api.v1.IdentityType identity_type = 1; */
        if (message.identityType !== 0)
            writer.tag(1, WireType.Varint).int32(message.identityType);
        /* string auth0_id = 2; */
        if (message.auth0Id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.auth0Id);
        /* string application_name = 3; */
        if (message.applicationName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.applicationName);
        /* ciam.backend.api.v1.EmailParams email_params = 4; */
        if (message.emailParams)
            EmailParams.internalBinaryWrite(message.emailParams, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string email_address = 5; */
        if (message.emailAddress !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.emailAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.SendPasswordResetEmailRequest
 */
export const SendPasswordResetEmailRequest = new SendPasswordResetEmailRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendPasswordResetEmailResponse$Type extends MessageType<SendPasswordResetEmailResponse> {
    constructor() {
        super("ciam.backend.api.v1.SendPasswordResetEmailResponse", []);
    }
    create(value?: PartialMessage<SendPasswordResetEmailResponse>): SendPasswordResetEmailResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendPasswordResetEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendPasswordResetEmailResponse): SendPasswordResetEmailResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SendPasswordResetEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.SendPasswordResetEmailResponse
 */
export const SendPasswordResetEmailResponse = new SendPasswordResetEmailResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateIdentityRequest$Type extends MessageType<UpdateIdentityRequest> {
    constructor() {
        super("ciam.backend.api.v1.UpdateIdentityRequest", [
            { no: 1, name: "identity_type", kind: "enum", T: () => ["ciam.backend.api.v1.IdentityType", IdentityType, "IDENTITY_TYPE_"], options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "identity_info", kind: "message", T: () => IdentityInfo, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "update_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateIdentityRequest>): UpdateIdentityRequest {
        const message = { identityType: 0, updateActive: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateIdentityRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateIdentityRequest): UpdateIdentityRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ciam.backend.api.v1.IdentityType identity_type */ 1:
                    message.identityType = reader.int32();
                    break;
                case /* ciam.backend.api.v1.IdentityInfo identity_info */ 2:
                    message.identityInfo = IdentityInfo.internalBinaryRead(reader, reader.uint32(), options, message.identityInfo);
                    break;
                case /* bool update_active */ 3:
                    message.updateActive = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateIdentityRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ciam.backend.api.v1.IdentityType identity_type = 1; */
        if (message.identityType !== 0)
            writer.tag(1, WireType.Varint).int32(message.identityType);
        /* ciam.backend.api.v1.IdentityInfo identity_info = 2; */
        if (message.identityInfo)
            IdentityInfo.internalBinaryWrite(message.identityInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool update_active = 3; */
        if (message.updateActive !== false)
            writer.tag(3, WireType.Varint).bool(message.updateActive);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.UpdateIdentityRequest
 */
export const UpdateIdentityRequest = new UpdateIdentityRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateIdentityResponse$Type extends MessageType<UpdateIdentityResponse> {
    constructor() {
        super("ciam.backend.api.v1.UpdateIdentityResponse", []);
    }
    create(value?: PartialMessage<UpdateIdentityResponse>): UpdateIdentityResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateIdentityResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateIdentityResponse): UpdateIdentityResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateIdentityResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.UpdateIdentityResponse
 */
export const UpdateIdentityResponse = new UpdateIdentityResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IssueTokenRequest$Type extends MessageType<IssueTokenRequest> {
    constructor() {
        super("ciam.backend.api.v1.IssueTokenRequest", [
            { no: 1, name: "identity_type", kind: "enum", T: () => ["ciam.backend.api.v1.IdentityType", IdentityType, "IDENTITY_TYPE_"], options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "token_lifetime", kind: "scalar", T: 5 /*ScalarType.INT32*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 3, name: "person_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IssueTokenRequest>): IssueTokenRequest {
        const message = { identityType: 0, tokenLifetime: 0, personId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IssueTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IssueTokenRequest): IssueTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ciam.backend.api.v1.IdentityType identity_type */ 1:
                    message.identityType = reader.int32();
                    break;
                case /* int32 token_lifetime */ 2:
                    message.tokenLifetime = reader.int32();
                    break;
                case /* string person_id */ 3:
                    message.personId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IssueTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ciam.backend.api.v1.IdentityType identity_type = 1; */
        if (message.identityType !== 0)
            writer.tag(1, WireType.Varint).int32(message.identityType);
        /* int32 token_lifetime = 2; */
        if (message.tokenLifetime !== 0)
            writer.tag(2, WireType.Varint).int32(message.tokenLifetime);
        /* string person_id = 3; */
        if (message.personId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.personId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.IssueTokenRequest
 */
export const IssueTokenRequest = new IssueTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IssueTokenResponse$Type extends MessageType<IssueTokenResponse> {
    constructor() {
        super("ciam.backend.api.v1.IssueTokenResponse", [
            { no: 1, name: "ciam_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "access_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "expiration_time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<IssueTokenResponse>): IssueTokenResponse {
        const message = { ciamId: "", accessToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<IssueTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IssueTokenResponse): IssueTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ciam_id */ 1:
                    message.ciamId = reader.string();
                    break;
                case /* string access_token */ 2:
                    message.accessToken = reader.string();
                    break;
                case /* google.protobuf.Timestamp expiration_time */ 3:
                    message.expirationTime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expirationTime);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IssueTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ciam_id = 1; */
        if (message.ciamId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ciamId);
        /* string access_token = 2; */
        if (message.accessToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessToken);
        /* google.protobuf.Timestamp expiration_time = 3; */
        if (message.expirationTime)
            Timestamp.internalBinaryWrite(message.expirationTime, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.IssueTokenResponse
 */
export const IssueTokenResponse = new IssueTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPasswordChangeURLRequest$Type extends MessageType<GetPasswordChangeURLRequest> {
    constructor() {
        super("ciam.backend.api.v1.GetPasswordChangeURLRequest", [
            { no: 1, name: "application_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPasswordChangeURLRequest>): GetPasswordChangeURLRequest {
        const message = { applicationName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPasswordChangeURLRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPasswordChangeURLRequest): GetPasswordChangeURLRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string application_name */ 1:
                    message.applicationName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPasswordChangeURLRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string application_name = 1; */
        if (message.applicationName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.applicationName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.GetPasswordChangeURLRequest
 */
export const GetPasswordChangeURLRequest = new GetPasswordChangeURLRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPasswordChangeURLResponse$Type extends MessageType<GetPasswordChangeURLResponse> {
    constructor() {
        super("ciam.backend.api.v1.GetPasswordChangeURLResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPasswordChangeURLResponse>): GetPasswordChangeURLResponse {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPasswordChangeURLResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPasswordChangeURLResponse): GetPasswordChangeURLResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPasswordChangeURLResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.GetPasswordChangeURLResponse
 */
export const GetPasswordChangeURLResponse = new GetPasswordChangeURLResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAuth0RecordsRequest$Type extends MessageType<DeleteAuth0RecordsRequest> {
    constructor() {
        super("ciam.backend.api.v1.DeleteAuth0RecordsRequest", [
            { no: 1, name: "identifier", kind: "message", T: () => Identifier }
        ]);
    }
    create(value?: PartialMessage<DeleteAuth0RecordsRequest>): DeleteAuth0RecordsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteAuth0RecordsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAuth0RecordsRequest): DeleteAuth0RecordsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.fhir.r4.core.Identifier identifier */ 1:
                    message.identifier = Identifier.internalBinaryRead(reader, reader.uint32(), options, message.identifier);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAuth0RecordsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.fhir.r4.core.Identifier identifier = 1; */
        if (message.identifier)
            Identifier.internalBinaryWrite(message.identifier, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.DeleteAuth0RecordsRequest
 */
export const DeleteAuth0RecordsRequest = new DeleteAuth0RecordsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAuth0RecordsResponse$Type extends MessageType<DeleteAuth0RecordsResponse> {
    constructor() {
        super("ciam.backend.api.v1.DeleteAuth0RecordsResponse", [
            { no: 1, name: "outcome", kind: "enum", T: () => ["ciam.backend.api.v1.DeletionOutcome", DeletionOutcome, "DELETION_OUTCOME_"] }
        ]);
    }
    create(value?: PartialMessage<DeleteAuth0RecordsResponse>): DeleteAuth0RecordsResponse {
        const message = { outcome: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteAuth0RecordsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAuth0RecordsResponse): DeleteAuth0RecordsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ciam.backend.api.v1.DeletionOutcome outcome */ 1:
                    message.outcome = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAuth0RecordsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ciam.backend.api.v1.DeletionOutcome outcome = 1; */
        if (message.outcome !== 0)
            writer.tag(1, WireType.Varint).int32(message.outcome);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ciam.backend.api.v1.DeleteAuth0RecordsResponse
 */
export const DeleteAuth0RecordsResponse = new DeleteAuth0RecordsResponse$Type();
/**
 * @generated ServiceType for protobuf service ciam.backend.api.v1.CIAMService
 */
export const CIAMService = new ServiceType("ciam.backend.api.v1.CIAMService", [
    { name: "CheckAuthorization", options: {}, I: CheckAuthorizationRequest, O: CheckAuthorizationResponse },
    { name: "BatchCheckAuthorization", options: {}, I: BatchCheckAuthorizationRequest, O: BatchCheckAuthorizationResponse },
    { name: "GetAssignedRoleFeatures", options: {}, I: GetAssignedRoleFeaturesRequest, O: GetAssignedRoleFeaturesResponse },
    { name: "ListAuthorizedOrganizations", options: {}, I: ListAuthorizedOrganizationsRequest, O: ListAuthorizedOrganizationsResponse },
    { name: "ListIdentities", options: {}, I: ListIdentitiesRequest, O: ListIdentitiesResponse },
    { name: "CreateIdentity", options: {}, I: CreateIdentityRequest, O: CreateIdentityResponse },
    { name: "UpdateIdentity", options: {}, I: UpdateIdentityRequest, O: UpdateIdentityResponse },
    { name: "IssueToken", options: {}, I: IssueTokenRequest, O: IssueTokenResponse },
    { name: "SendPasswordResetEmail", options: {}, I: SendPasswordResetEmailRequest, O: SendPasswordResetEmailResponse },
    { name: "GetPasswordChangeURL", options: {}, I: GetPasswordChangeURLRequest, O: GetPasswordChangeURLResponse },
    { name: "DeleteAuth0Records", options: {}, I: DeleteAuth0RecordsRequest, O: DeleteAuth0RecordsResponse }
]);
