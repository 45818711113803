import {GrpcWebFetchTransport} from '@protobuf-ts/grpcweb-transport';
import {buildUrl} from 'build-url-ts';

function getHost() {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'standalone'
  ) {
    //TODO: Read config correctly
    //return config.getString('IDV_PARTICIPANT_BFF_BASE_URL');
    return 'http://localhost:3002';
  }

  // protobuf-ts generated clients add the prefix `idv.participant_bff.api.v1`
  // Must match the path in envoy.yaml
  return buildUrl(`${window.location.protocol}//${window.location.host}`, {
    path: 'api/',
  });
}

export const createParticipantBffRpcTransport = () =>
  new GrpcWebFetchTransport({
    baseUrl: getHost() ?? '',
  });
