/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter ts_nocheck,eslint_disable
// @generated from protobuf file "idv/participant-bff/api/v1/idv_participant_bff_service.proto" (package "idv.participant_bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { VerificationState } from "../../../backend/api/v1/idv_types";
import { IdvMethod } from "../../../backend/api/v1/idv_types";
/**
 * StartIdvForParticipant request.
 *
 * @generated from protobuf message idv.participant_bff.api.v1.StartIdvForParticipantRequest
 */
export interface StartIdvForParticipantRequest {
    /**
     * IDV Method/Vendor to be used.
     *
     * @generated from protobuf field: idv.backend.api.v1.IdvMethod idv_method = 1;
     */
    idvMethod: IdvMethod;
    /**
     * Bundle Identifier. IDV Participant BFF will call action-be to fetch Bundle info and infer Patient ID.
     *
     * @generated from protobuf field: string bundle_id = 2;
     */
    bundleId: string;
}
/**
 * Response for StartIdvForParticipant RPC.
 *
 * @generated from protobuf message idv.participant_bff.api.v1.StartIdvForParticipantResponse
 */
export interface StartIdvForParticipantResponse {
    /**
     * Verification state of the Patient.
     *
     * @generated from protobuf field: idv.backend.api.v1.VerificationState verification_state = 1;
     */
    verificationState: VerificationState;
    /**
     * URL of the session created.
     *
     * @generated from protobuf field: string session_url = 2;
     */
    sessionUrl: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class StartIdvForParticipantRequest$Type extends MessageType<StartIdvForParticipantRequest> {
    constructor() {
        super("idv.participant_bff.api.v1.StartIdvForParticipantRequest", [
            { no: 1, name: "idv_method", kind: "enum", T: () => ["idv.backend.api.v1.IdvMethod", IdvMethod, "IDV_METHOD_"] },
            { no: 2, name: "bundle_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StartIdvForParticipantRequest>): StartIdvForParticipantRequest {
        const message = { idvMethod: 0, bundleId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartIdvForParticipantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartIdvForParticipantRequest): StartIdvForParticipantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* idv.backend.api.v1.IdvMethod idv_method */ 1:
                    message.idvMethod = reader.int32();
                    break;
                case /* string bundle_id */ 2:
                    message.bundleId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartIdvForParticipantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* idv.backend.api.v1.IdvMethod idv_method = 1; */
        if (message.idvMethod !== 0)
            writer.tag(1, WireType.Varint).int32(message.idvMethod);
        /* string bundle_id = 2; */
        if (message.bundleId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bundleId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message idv.participant_bff.api.v1.StartIdvForParticipantRequest
 */
export const StartIdvForParticipantRequest = new StartIdvForParticipantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartIdvForParticipantResponse$Type extends MessageType<StartIdvForParticipantResponse> {
    constructor() {
        super("idv.participant_bff.api.v1.StartIdvForParticipantResponse", [
            { no: 1, name: "verification_state", kind: "enum", T: () => ["idv.backend.api.v1.VerificationState", VerificationState, "VERIFICATION_STATE_"] },
            { no: 2, name: "session_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StartIdvForParticipantResponse>): StartIdvForParticipantResponse {
        const message = { verificationState: 0, sessionUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartIdvForParticipantResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartIdvForParticipantResponse): StartIdvForParticipantResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* idv.backend.api.v1.VerificationState verification_state */ 1:
                    message.verificationState = reader.int32();
                    break;
                case /* string session_url */ 2:
                    message.sessionUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartIdvForParticipantResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* idv.backend.api.v1.VerificationState verification_state = 1; */
        if (message.verificationState !== 0)
            writer.tag(1, WireType.Varint).int32(message.verificationState);
        /* string session_url = 2; */
        if (message.sessionUrl !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sessionUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message idv.participant_bff.api.v1.StartIdvForParticipantResponse
 */
export const StartIdvForParticipantResponse = new StartIdvForParticipantResponse$Type();
/**
 * @generated ServiceType for protobuf service idv.participant_bff.api.v1.IdvParticipantBffService
 */
export const IdvParticipantBffService = new ServiceType("idv.participant_bff.api.v1.IdvParticipantBffService", [
    { name: "StartIdvForParticipant", options: { "ciam.annotations.permissions": { ciam: ["ALLOW_AUTHENTICATED"] } }, I: StartIdvForParticipantRequest, O: StartIdvForParticipantResponse }
]);
