import {RpcMetadata, RpcOptions} from '@protobuf-ts/runtime-rpc';
import {Auth} from '@verily-src/auth';

export default async (): Promise<RpcOptions> => {
  const interceptor = await Auth.getInterceptor();
  let options: RpcOptions = {};
  if (process.env.LOCAL_TOKEN) {
    const metadata: RpcMetadata = {
      Authorization: 'Bearer ' + process.env.LOCAL_TOKEN,
    };
    options = {
      meta: metadata,
    };
  } else {
    options = {
      interceptors: [interceptor],
    };
  }
  return options;
};
