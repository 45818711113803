import {CircularProgress, Paper} from '@mui/material';
import {createVeriffFrame, MESSAGES} from '@veriff/incontext-sdk';
import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import {api} from '@verily-src/phaf-unified-api';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {startIdvForParticipant} from '../../api/startIdvForParticipant';
import {Error} from '../../components/Error/Error';
import MockVeriffFrame from '../../components/Mocks/MockVeriffFrame';
import generateFakeAccessToken from '../../helpers/generateFakeAccessToken';
import {useDialog} from '../../helpers/useDialog';
import styles from './IdentityVerification.module.css';

export default function Example() {
  const [sessionUrl, setSessionUrl] = useState<string>('');
  const [renderLoadingIcon, setRenderLoadingIcon] = useState<Boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [searchParams] = useSearchParams();
  const bundleId = searchParams.get('bundle');

  const mockVeriffFrameState = useDialog();

  if (process.env.BDD_TEST) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (api as typeof VerilyMeApi).bundle.advance = () => {
      alert('Bundle advanced');
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (api as typeof VerilyMeApi).bundle.back = () => {
      alert('Bundle was moved back');
    };
  }
  // Add fake auth token for auth mocking
  if (config.getBoolean('MOCK_AUTH')) {
    try {
      const [searchParams] = useSearchParams();
      const patientId = searchParams.get('mockPatientId');
      const token = generateFakeAccessToken(patientId ?? '', [
        'PROFESSIONAL_PERMISSION_IDV_ID_VERIFICATION_VIEW',
        'PROFESSIONAL_PERMISSION_IDV_ID_VERIFICATION_EDIT',
      ]);
      window.localStorage.setItem('test_access_token', token);
    } catch (err) {
      alert(err);
    }
  }

  useEffect(() => {
    if (!bundleId) {
      setErrorMessage('Missing bundle Id!');
      setRenderLoadingIcon(false);
      return;
    }
    setRenderLoadingIcon(true);
    const fetchSessionUrl = async () => {
      try {
        const startIdvResponse = await startIdvForParticipant(bundleId);
        setSessionUrl(startIdvResponse?.sessionUrl ?? '');
        setRenderLoadingIcon(false);
        setErrorMessage('');
      } catch (err) {
        console.error(err);
        setRenderLoadingIcon(false);
        setErrorMessage('Error fetching IDV session data.');
      }
    };
    fetchSessionUrl();
  }, []);

  const handleEvent = (msg: MESSAGES) => {
    switch (msg) {
      case MESSAGES.FINISHED:
        (api as typeof VerilyMeApi).bundle.advance();
        break;
      case MESSAGES.CANCELED:
        (api as typeof VerilyMeApi).bundle.back();
        break;
    }
  };

  function startIdv() {
    if (config.getBoolean('MOCK_VERIFF')) {
      if (!mockVeriffFrameState.isOpen) {
        mockVeriffFrameState.openDialog();
      }
    } else {
      createVeriffFrame({
        url: sessionUrl,
        onEvent: handleEvent,
      });
    }
  }

  let content = <></>;

  if (renderLoadingIcon) {
    content = (
      <div className={styles.spinnerOverlay}>
        <CircularProgress data-testid="spinner" />
      </div>
    );
  } else {
    startIdv();
    content = (
      <div className="styles.paymentContainer">
        {config.getBoolean('MOCK_VERIFF') && (
          <MockVeriffFrame
            open={mockVeriffFrameState.isOpen}
            onClose={() => {
              handleEvent(MESSAGES.CANCELED);
              mockVeriffFrameState.closeDialog();
            }}
            onConfirm={() => {
              handleEvent(MESSAGES.FINISHED);
            }}
            url={sessionUrl}
          />
        )}

        <Paper
          sx={{
            width: '100%',
            boxShadow: 'none',
          }}
        >
          {errorMessage !== null && errorMessage.length > 0 ? (
            <Error message={errorMessage} />
          ) : (
            <></>
          )}
        </Paper>
      </div>
    );
  }

  return <div data-testid="idv-container">{content}</div>;
}
