import {
  IdvMethod,
  VerificationState,
} from '@verily-src/verily1-protos/idv/backend/api/v1/idv_types';
import {StartIdvForParticipantResponse} from '@verily-src/verily1-protos/idv/participant-bff/api/v1/idv_participant_bff_service';
import {IdvParticipantBffServiceClient} from '@verily-src/verily1-protos/idv/participant-bff/api/v1/idv_participant_bff_service.client';
import {createParticipantBffRpcTransport} from './createRpcTransport';
import getRpcOptions from './getRpcOptions';
import maybeMockRpc from './maybeMockRpc';

export const startIdvForParticipant = async (
  bundleId: string
): Promise<StartIdvForParticipantResponse | undefined> => {
  return await maybeMockRpc(
    bundleId,
    async bundleId => {
      const client = new IdvParticipantBffServiceClient(
        createParticipantBffRpcTransport()
      );
      const response = (
        await client.startIdvForParticipant(
          {
            bundleId: bundleId,
            idvMethod: IdvMethod.VERIFF,
          },
          await getRpcOptions()
        )
      ).response;
      return response;
    },
    _ => mockStartIdvForParticipantResponse
  );
};

const mockStartIdvForParticipantResponse =
  StartIdvForParticipantResponse.create({
    sessionUrl: 'mock-url',
    verificationState: VerificationState.IN_PROGRESS,
  });
