/* eslint-disable node/no-extraneous-import */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {Button} from '@verily-src/react-design-system';

interface MockVeriffFrameProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  url: string;
}

export default function MockVeriffFrame({
  open,
  onClose,
  onConfirm,
  url,
}: MockVeriffFrameProps) {
  // reset state when the dialog is opened/closed
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Dialog
        data-testid="manual-idv-dialog"
        maxWidth="md"
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiDialog-paper': {
            /* Layout */
            maxWidth: '560px',
            width: '100%',

            /* Style */
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle>Mock Veriff Frame</DialogTitle>

        <DialogContent
          sx={{
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px 8px',
          }}
        >
          <Typography>{'URL: ' + url}</Typography>
        </DialogContent>

        <DialogActions
          sx={{
            alignItems: 'center',
            alignSelf: 'stretch',
            display: 'flex',
            gap: '8px',
            justifyContent: 'flex-end',
            padding: '4px 24px 24px 24px',
          }}
        >
          <Button
            aria-label="Exit"
            color="primary"
            label="Exit"
            name="exit-button"
            onClick={handleClose}
            role="button"
            size="medium"
            variant="outlined"
          />

          <Button
            aria-label="Confirm"
            color="primary"
            label="Confirm"
            name="confirm-button"
            onClick={onConfirm}
            role="button"
            size="medium"
            variant="filled"
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
